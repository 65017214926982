'use strict';

import http from '@/utils/http';
// import { getFormData } from '@/utils';

export default {
  // 嘉宾分页
  guestsUrl: '/guest/page',

  // 新增嘉宾
  guestsAdd(code, data) {
    return http.post('/guest/addOrUpDate', data, { code });
  },
  // 编辑嘉宾
  guestsUpdate(code, data) {
    return http.post('/guest/addOrUpDate', data, { code });
  },
  // 删除嘉宾
  guestsDelete(code, params) {
    return http.delete('/guest/delete', { params, code });
  },

  // 获取下拉数据
  getCategoryTree(code, params) {
    return http.get('/category/getCategoryTree', {
      code,
      params,
    });
  },
  // 首页显示状态
  updateAutoSwitch(code, { id, autoSwitch }) {
    return http.put(
      `/account/memberGroup/updateAutoSwitch/${id}/${autoSwitch}`,
      {},
      { code }
    );
  },
  // 查询单条资料
  getfindById(code, params) {
    return http.get(`/guest/findById`, { params, code });
  },
  // 更改首页是否显示接口
  updateShowStatus(code, data) {
    return http.post('/guest/updateShowStatus', data, { code });
  },

  publish(code, data) {
    // 发布
    return http.post('/guest/publish', data, {
      code,
    });
  },
};
