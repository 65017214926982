<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        allow-selection
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1640, y: gridHeight }"
      >
        <template #receiveConsult="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
        <!-- todo 需要补齐  -->
        <template #pushStatus="{ record }">
          <div v-if="record.pushStatus === 0">未发布</div>
          <div v-else-if="record.pushStatus === 1">已发布</div>
        </template>
        <template #photoUrl="{ record }">
          <img :src="record.photoUrl" width="50" />
        </template>
        <template #isHome="{ record }">
          <div v-if="record.isHome == 1">是</div>
          <div v-else>否</div>
        </template>

        <!--      首页显示状态  ("首页展示状态 1:已展示  0:为展示")   -->
        <template #showStatus="{ record }">
          <a-switch
            :checked="record.showStatus"
            checked-children="启用"
            un-checked-children="禁用"
            :checked-value="Number(1)"
            :un-checked-value="Number(0)"
            @click="handleClickSwitch(record)"
          />
        </template>
      </grid>
    </div>
  </div>

  <iss-edit
    v-model:visible="visible"
    :init-value="activeItem"
    @fnOk="handleFnOkByEdit"
  />
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import { Tag, Switch, message } from 'ant-design-vue';
// import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
// import contactApi from '@/api/contact.js';
import guestsApi from '@/api/guests.js';
import issEdit from './components/edit';
// import { EyeOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    ATag: Tag,
    issEdit,
    // EyeOutlined,
    ASwitch: Switch,
  },
  setup() {
    const gridRef = ref();
    const store = useStore();
    const state = reactive({
      visible: false,
      search: {},
      // TagList: [],
      activeItem: {},
    });

    return {
      gridRef,
      store,
      ...toRefs(state),
      items: [
        { key: 'guestName', label: '嘉宾名字' },

        {
          key: 'guestType',
          label: '嘉宾类型',
          type: 'select',
          dataset: [
            { value: '1', label: '特邀嘉宾' },
            { value: '0', label: '演讲嘉宾' },
          ],
        },
        {
          key: 'pushStatus',
          label: '发布状态',
          type: 'select',
          dataset: [
            { value: '1', label: '已发布' },
            { value: '0', label: '未发布' },
          ],
        },
        {
          key: 'language',
          label: '语言',
          type: 'select',
          dataset: [
            { value: 'English', label: 'English' },
            { value: 'Chinese', label: 'Chinese' },
          ],
        },
      ],
      columns: [
        { dataIndex: 'language', title: '语言', width: 80, ellipsis: true },
        {
          dataIndex: 'photoUrl',
          title: '嘉宾图片',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'photoUrl' },
        },
        {
          dataIndex: 'guestType',
          title: '嘉宾类型',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'guestName',
          title: '嘉宾姓名',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'guestInformation',
          title: '嘉宾简介',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'guestTrade',
          title: '嘉宾行业',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'guestOccupation',
          title: '嘉宾职业',
          width: 120,
          ellipsis: true,
        },
        // 点击打开议程 详情页
        {
          dataIndex: 'pushStatus',
          title: '发布状态',
          width: 120,
          slots: { customRender: 'pushStatus' },
          ellipsis: true,
        },

        {
          dataIndex: 'showStatus',
          title: '显示状态',
          width: 120,
          slots: { customRender: 'showStatus' },
          ellipsis: true,
        },
        {
          dataIndex: 'sort',
          title: '排序',
          width: 120,
          slots: { customRender: 'sort' },
          ellipsis: true,
        },
        {
          dataIndex: 'isHome',
          title: '是否上首页',
          width: 120,
          slots: { customRender: 'isHome' },
          ellipsis: true,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 80,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'classify:add',
          fnClick: () => {
            state.visible = true;
            state.activeItem = {};
          },
        },

        // todo 发布接口
        {
          type: 'isPublish',
          label: '发布',
          icon: 'ExportOutlined',
          permission: 'agenda:publish',
          fnClick: () => {
            guestsApi.publish('', '').then(() => {
              message.success('发布成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],
      options: [
        {
          type: 'update',
          label: '编辑',
          permission: 'agenda:update',
          fnClick: record => {
            state.visible = true;
            state.activeItem = { ...record };
            let id = record.id;
            guestsApi.getfindById('', { id }).then(res => {
              state.activeItem = { ...res };
            });
          },
        },
        {
          type: 'delete',
          permission: 'resources:delete',
          fnClick: ({ id }) => {
            guestsApi.guestsDelete('resources:delete', { id: id }).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],
      url: guestsApi.guestsUrl,
      gridHeight: document.body.clientHeight - 386,
      handleClickSwitch(record) {
        guestsApi
          .updateShowStatus('', {
            id: record.id,
            showStatus:
              record.showStatus === null || record.showStatus === 0 ? 1 : 0,
          })
          .then(() => {
            message.success('操作成功');
            gridRef.value.refreshGrid();
          });
      },

      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        state.search = temp;
      },
      handleFnOkByEdit: value => {
        let type = 'guestsAdd';
        const { id } = value;
        id && (type = 'guestsUpdate');

        guestsApi[type](`stand:${type}`, value).then(() => {
          message.success('操作成功');
          state.visible = false;
          gridRef.value.refreshGrid();
          setTimeout(() => {}, 1500);
        });
        // 保存失败后 恢复保存按钮
        setTimeout(() => {}, 1500);
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}
.contact-page {
  & .title {
    .right {
      i {
        margin-left: 3px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
