<template>
  <a-modal
    :visible="visible"
    :title="form.id ? '编辑' : '新增'"
    :mask-closable="false"
    :width="750"
    class="model"
    @ok="handleOk"
    style="top: 20px"
    @cancel="handleCancel"
  >
    <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
      <a-form-item label="语言" v-bind="validateInfos.language">
        <a-radio-group v-model:value="form.language" :options="languageList" />
      </a-form-item>
      <a-form-item label="嘉宾类型" v-bind="validateInfos.guestType">
        <a-select v-model:value="form.guestType" placeholder="请选择嘉宾类型">
          <a-select-option
            v-for="item in guestTypeList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="嘉宾姓名" v-bind="validateInfos.guestName">
        <a-input v-model:value="form.guestName" placeholder="请输入嘉宾姓名" />
      </a-form-item>
      <a-form-item label="嘉宾图片" v-bind="validateInfos.photoUrl">
        <iss-image-upload
          v-model:value="form.photoUrl"
          :limit-size="10"
          list-type="picture-card"
          accept=".jpe,.jpeg,.jpg,.png"
        />
        <div class="font-color font-size">
          建议上传16:9，大小不超过1M，格式为JPG、JPEG或PNG的图片
        </div>
      </a-form-item>
      <a-form-item label="嘉宾简介" v-bind="validateInfos.guestInformation">
        <a-textarea
          v-model:value="form.guestInformation"
          auto-size
          show-count
          :maxlength="800"
          placeholder="请输入嘉宾简介"
        />
      </a-form-item>
      <a-form-item label="嘉宾行业" v-bind="validateInfos.guestTrade">
        <a-input v-model:value="form.guestTrade" placeholder="请输入嘉宾行业" />
      </a-form-item>
      <a-form-item label="嘉宾职业" v-bind="validateInfos.guestOccupation">
        <a-input
          v-model:value="form.guestOccupation"
          placeholder="请输入嘉宾职业"
        />
      </a-form-item>
      <a-form-item label="是否上首页" v-bind="validateInfos.isHome">
        <a-switch
          v-model:checked="form.isHome"
          checked-children="是"
          un-checked-children="否"
          :checked-value="Number(1)"
          :un-checked-value="Number(0)"
        />
      </a-form-item>
      <a-form-item label="排序" v-bind="validateInfos.sort">
        <a-input-number v-model:value="form.sort" :min="1" :max="99" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { reactive, toRaw, toRefs, watch, ref, onBeforeUnmount } from 'vue';
import { Form, Select, Radio, InputNumber, Switch } from 'ant-design-vue';
import IssImageUpload from '@/components/imageUpload';
// import { useRoute } from 'vue-router';
// import agendaApi from '@/api/agenda';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARadioGroup: Radio.Group,
    AInputNumber: InputNumber,
    ASwitch: Switch,
    IssImageUpload,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    // const route = useRoute();
    // const campaignId = route.params.campaignId;
    const editorRef = ref();
    const required = { required: true, message: '不能为空' };
    const form = reactive({
      id: '',
      // agendaName: '',
      guestType: '',
      guestName: '',
      photoUrl: '',
      guestInformation: '',
      guestTrade: '',
      guestOccupation: '',
      sort: '',
      isHome: 0,
    });
    const state = reactive({
      agreementDemList: [],
      languageList: [
        { label: '中文', value: 'Chinese' },
        { label: '英文', value: 'English' },
      ],
      guestTypeList: [
        { value: '1', label: '特邀嘉宾' },
        { value: '0', label: '演讲嘉宾' },
      ],
    });
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      guestType: [required],
      guestName: [required, { max: 30, message: '嘉宾姓名不能超过30个字符' }],
      // photoUrl: [required],
      // agendaName: [required],
      guestInformation: [required],
      guestTrade: [{ max: 50, message: '嘉宾行业不能超过50个字符' }],
      guestOccupation: [{ max: 100, message: '嘉宾职业不能超过100个字符' }],
    });

    watch(
      () => props.initValue,
      initValue => {
        if (initValue.id) {
          Object.assign(form, initValue);
        } else {
          resetFields();
        }
      }
    );

    onBeforeUnmount(() => {
      editorRef.value && editorRef?.value.remove();
    });

    return {
      ...toRefs(state),
      form,
      validateInfos,
      handleCancel: () => context.emit('update:visible', false),
      handleOk: () => {
        validate().then(() => {
          context.emit('fnOk', toRaw(form));
        });
      },
    };
  },
};
</script>
<style scoped>
/* .model {
  max-height: 600px;
} */
</style>
